// @ts-strict-ignore
import bindAll from 'lodash/bindAll';
import debounce from 'lodash/debounce';

import Field from 'src/form_filler/fields/field';
import { findEl } from 'src/helpers/finders';
import { setContext } from 'src/helpers/notify';
import { formatNumber } from 'src/helpers/number';
import parsePhoneNumber from 'src/helpers/phone_number';
import parseSsn from 'src/helpers/ssn';
import { removeAllTooltips, removeTooltip, setTooltip } from 'src/helpers/tooltip';
import valToString from 'src/helpers/val_to_string';

const TEXT_LIMIT_MESSAGE = 'Sorry, you have run out of space on this field.';
const REQUIRED_MESSAGE = 'Please complete this field.';

class TextField extends Field {
  props: any;
  debouncedStoreValue: Callback;
  debouncedRemoveLineLimitError: Function;
  oldValue: string;

  constructor(element, data) {
    super(element, data);

    if (this.isLocked) { return; }

    this.debouncedStoreValue = debounce(this.storeValue, 500);
    bindAll(
      this,
      'debouncedStoreValue',
      'toggleRequiredBorder',
      'sanitizeAndStoreValue',
      'handleInputChange',
      'storeOldValue',
      'formatPhoneNumber',
    );
    this.debouncedRemoveLineLimitError =
      debounce(this.removeLineLimitError, 1500, { leading: false, trailing: true });

    this.props = data;
    this.storeOldValue();
    this.sanitizeAndStoreValue();

    this.toggleRequiredBorder();
    this.$requestedField
      .on('input', this.handleInputChange)
      .on('keyup', this.toggleRequiredBorder)
      .on('change', this.debouncedStoreValue)
      .on('blur', this.sanitizeAndStoreValue);
  }

  get $requestedField(): JQuery<HTMLTextAreaElement> {
    return $(findEl(this.element, 'textarea', '.requested-field'));
  }

  get isLocked(): boolean {
    return this.element.classList.contains('locked-field');
  }

  getValue(): string {
    setContext({ fieldData: this.data });
    if (this.isLocked) {
      // this happens for locked fields (fields submitted on the previous step)
      // so we can use the data that was passed in directly vs in unlocked fields
      // we need to always get the value dynamically
      return valToString(this.data.value);
    }

    return valToString(this.$requestedField.val());
  }

  handleInputChange(): void {
    const { oldValue } = this;
    const newValue = this.getValue();

    if (scrollHeightIncrease(this.$requestedField, oldValue, newValue)) {
      this.$requestedField.val(this.oldValue);
      this.triggerLineLimitError();
    } else {
      this.removeLineLimitError();
    }
    this.storeOldValue();
    this.debouncedStoreValue();
  }

  storeOldValue(): void {
    this.oldValue = this.getValue();
  }

  removeLineLimitError(): void {
    removeTooltip(this.$requestedField, TEXT_LIMIT_MESSAGE);
  }

  triggerLineLimitError(): void {
    setTooltip(this.$requestedField, TEXT_LIMIT_MESSAGE);

    this.debouncedRemoveLineLimitError();
  }

  sanitizeAndStoreValue(): void {
    let value = this.getValue();
    const { format, precision } = this.props;

    if (format === 'number') {
      if (Number.isInteger(precision)) {
        value = formatNumber(value, { precision });
      }
      this.$requestedField.val(value.trim());
    } else if (format === 'xxx-xxx-xxxx') {
      this.formatPhoneNumber(value, this.$requestedField);
    } else if (format === 'xxx-xx-xxxx') {
      this.formatSsn(value, this.$requestedField);
    }

    this.storeValue();
  }

  toggleRequiredBorder(): void {
    const value = this.$requestedField.val();

    this.$requestedField.toggleClass('empty', !value)
      .toggleClass('required-danger', !value && this.isRequired());
    if (value) {
      removeTooltip(this.$requestedField, REQUIRED_MESSAGE);
    }
  }

  formatPhoneNumber(inputValue: string, requestedField: JQuery): void {
    if (!inputValue) {
      removeAllTooltips();
      return;
    }

    const result = parsePhoneNumber(inputValue);

    if ('error' in result) {
      removeAllTooltips();
      setTooltip(requestedField, result.error);
      return;
    }

    requestedField.val(result.phoneNumber);
    removeAllTooltips();
  }

  formatSsn(inputValue: string, requestedField: JQuery): void {
    if (!inputValue) {
      removeAllTooltips();
      return;
    }

    const result = parseSsn(inputValue);

    if ('error' in result) {
      removeAllTooltips();
      setTooltip(requestedField, result.error);
      return;
    }

    requestedField.val(result.ssn);
    removeAllTooltips();
  }
}

// private

function scrollHeightIncrease(
  $textArea: JQuery<HTMLTextAreaElement>,
  oldValue: string,
  newValue,
): boolean {
  if ($textArea.attr('maxlength')) { return false; }
  if (newValue.length === 0) { return false; }

  const textArea: HTMLTextAreaElement = $textArea.get(0);
  const clone = textArea.cloneNode();

  textArea.parentNode.appendChild(clone);

  clone.value = oldValue;
  const oldHeight = Math.max(clone.clientHeight, clone.scrollHeight);

  clone.value = newValue;
  const newHeight = Math.max(clone.clientHeight, clone.scrollHeight);

  clone.remove();

  return newHeight - oldHeight > 5;
}

export default TextField;
