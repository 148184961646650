import { Controller } from '@hotwired/stimulus';

class ResizeController extends Controller {
  static targets = ['element'];
  static values = {
    minHeight: Number,
    minWidth: Number,
  };

  elementTarget!: HTMLElement;
  bothHandle!: HTMLDivElement;
  horizontalHandle!: HTMLDivElement;
  verticalHandle!: HTMLDivElement;
  minWidthValue!: number;
  minHeightValue!: number;

  connect(): void {
    this.startResizing = this.startResizing.bind(this);
    this.resizeBoth = this.resizeBoth.bind(this);
    this.resizeHorizontal = this.resizeHorizontal.bind(this);
    this.resizeVertical = this.resizeVertical.bind(this);
    this.stopResizing = this.stopResizing.bind(this);
    this.clickEventStopPropagation = this.clickEventStopPropagation.bind(this);

    this.bothHandle = this.#createResizeHandle('resize-handle--both');
    this.horizontalHandle = this.#createResizeHandle('resize-handle--horizontal');
    this.verticalHandle = this.#createResizeHandle('resize-handle--vertical');
  }

  disconnect(): void {
    this.bothHandle.remove();
    this.verticalHandle.remove();
    this.horizontalHandle.remove();
  }

  startResizing(event: MouseEvent): void {
    event.stopPropagation();

    if (!(event.target instanceof HTMLElement)) { return; }

    if (event.target.classList.contains('resize-handle--both')) {
      document.addEventListener('mousemove', this.resizeBoth);
    }
    if (event.target.classList.contains('resize-handle--horizontal')) {
      document.addEventListener('mousemove', this.resizeHorizontal);
    }
    if (event.target.classList.contains('resize-handle--vertical')) {
      document.addEventListener('mousemove', this.resizeVertical);
    }

    document.addEventListener('mouseup', this.stopResizing);
  }

  resizeBoth(event: MouseEvent): void {
    const { top, left } = this.elementTarget.getBoundingClientRect();
    const boundWidth = Math.max(this.minWidthValue, event.clientX - left);
    const boundHeight = Math.max(this.minHeightValue, event.clientY - top);

    this.elementTarget.style.height = `${boundHeight}px`;
    this.elementTarget.style.width = `${boundWidth}px`;
  }

  resizeHorizontal(event: MouseEvent): void {
    const { left } = this.elementTarget.getBoundingClientRect();
    const boundWidth = Math.max(this.minWidthValue, event.clientX - left);

    this.elementTarget.style.width = `${boundWidth}px`;
  }

  resizeVertical(event: MouseEvent): void {
    const { top } = this.elementTarget.getBoundingClientRect();
    const boundHeight = Math.max(this.minHeightValue, event.clientY - top);

    this.elementTarget.style.height = `${boundHeight}px`;
  }

  stopResizing(): void {
    // Workaround to prevent mouse up from also propagating a click event, which can
    // trigger unintended event handlers
    document.addEventListener('click', this.clickEventStopPropagation, true);

    document.removeEventListener('mousemove', this.resizeBoth);
    document.removeEventListener('mousemove', this.resizeHorizontal);
    document.removeEventListener('mousemove', this.resizeVertical);
    document.removeEventListener('mouseup', this.stopResizing);
    this.elementTarget.dispatchEvent(new Event('resize:stop'));
  }

  clickEventStopPropagation(event: Event): void {
    event.stopPropagation();
    document.removeEventListener('click', this.clickEventStopPropagation, true);
  }

  #createResizeHandle(className: string): HTMLDivElement {
    const handle = document.createElement('div');
    handle.classList.add(className);
    handle.addEventListener('mousedown', this.startResizing);
    this.elementTarget.appendChild(handle);

    return handle;
  }
}

export default ResizeController;
