// @ts-strict-ignore
import { connect } from 'react-redux';

import ResponsesIndex from 'src/responses/components/index';

function mapStateToProps(state) {
  const {
    meta: { queryParams },
  } = state.responses;

  return {
    queryParams,
  };
}

export default connect(mapStateToProps)(ResponsesIndex);
