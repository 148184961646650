// @ts-strict-ignore
import { Json, snakeCaseKeys } from 'src/helpers/json';
import BulkCheckboxController from './bulk_checkbox_controller';
import { pluralizeWithCount } from 'src/helpers/pluralize';
import { generateFilteredFormSetData, generateSelectedFormSetData }
  from 'src/form_sets/helpers/form_set_data_generator';
import { Props } from './responses_index_controller';
import Feature from 'src/helpers/feature';
import { findEls } from 'src/helpers/finders';
import { paramsFromUrl } from 'src/responses/helpers/query_params';
import { defaultPageSize } from 'src/constants';

class ResponsesSelectionController extends BulkCheckboxController {
  static values = {
    props: Object,
    ...super.values,
  };

  static targets = [
    'appliedFilter',
    'bulkActionToolbar',
    'deselectAcrossPages',
    'filter',
    'pagination',
    'pdfDisabledMessage',
    'pdfGenerationOption',
    'searchInput',
    'searchToolbar',
    'selectAcrossPages',
    ...super.targets,
  ];

  appliedFilterTargets: HTMLDivElement[];
  bulkActionToolbarTarget: HTMLElement;
  deselectAcrossPagesTarget: HTMLElement;
  filterTargets: HTMLDivElement[];
  hasPaginationTarget: boolean;
  paginationTarget: HTMLInputElement;
  pdfDisabledMessageTarget: HTMLElement;
  pdfGenerationOptionTargets: HTMLElement[];
  searchInputTarget: HTMLInputElement;
  searchToolbarTarget: HTMLElement;
  selectAcrossPagesTarget: HTMLElement;

  allSelectedAcrossPages: boolean;
  propsValue: Props;

  connect(): void {
    this.allSelectedAcrossPages = false;
    this.#updateUI();
  }

  toggleOne(): void {
    super.toggleOne();
    this.#updateUI();
  }

  toggleAll(): void {
    super.toggleAll();
    this.#updateUI();
  }

  selectAllAcrossPages(): void {
    this.allSelectedAcrossPages = true;
    super.selectAll();
    this.#updateUI();
  }

  deselectAllAcrossPages(): void {
    this.allSelectedAcrossPages = false;
    super.deselectAll();
    this.#updateUI();
  }

  setSelectedCountText(): void {
    const selectedCount = this.#getSelectedCount();
    this.selectedCountTarget.textContent =
      `${pluralizeWithCount(selectedCount, this.itemDescriptionValue)} selected`;
  }

  setHiddenField(event: DOMEvent): void {
    const { campaign } = this.propsValue;
    const hiddenInputs: HTMLInputElement[] =
      findEls(event.currentTarget, 'input', '[type="hidden"]');

    hiddenInputs.forEach((element) => {
      if (element.name === 'form_set_data') {
        element.value = JSON
          .stringify(snakeCaseKeys(this.#generateFormSetData(campaign.id)));
      } else if (element.name === 'selected_responses_count') {
        element.value = this.#getSelectedCount().toString();
      }
    });
  }

  toggleFiltersEnabled(): void {
    const selectedCount = this.#getSelectedCount();
    const hasSearchTerm = Boolean(this.searchInputTarget.value);
    const disable = hasSearchTerm || selectedCount > 0;
    const allFilterTargets = this.filterTargets
      .concat(this.appliedFilterTargets);

    allFilterTargets.forEach((element) => {
      this.#toggleElementEnabled(element, disable);
    });
  }

  #updateUI(): void {
    this.#setToolbarVisibility();
    this.#setAcrossPagesOptionVisibility();
    this.#togglePdfLinksEnabled();
    this.toggleFiltersEnabled();
  }

  #setToolbarVisibility(): void {
    this.#setAcrossPagesOptionVisibility();

    const selectedCount = this.#getSelectedCount();

    if (selectedCount > 0) {
      this.bulkActionToolbarTarget.classList.remove('hide');
      this.searchToolbarTarget.classList.add('hide');
    } else {
      this.bulkActionToolbarTarget.classList.add('hide');
      this.searchToolbarTarget.classList.remove('hide');
    }
  }

  #setAcrossPagesOptionVisibility(): void {
    const allSelected = this.areAllCheckboxesSelected();
    const { totalPages } = this.#getPagination();

    if (!allSelected || totalPages < 2) {
      this.allSelectedAcrossPages = false;
      this.setSelectedCountText();
      this.#hideSelectAcrossPagesOptions();
    } else if (this.allSelectedAcrossPages) {
      this.#showDeselectAcrossPagesOption();
    } else {
      this.#showSelectAcrossPagesOption();
    }
  }

  #hideSelectAcrossPagesOptions(): void {
    this.selectAcrossPagesTarget.classList.add('hide');
    this.deselectAcrossPagesTarget.classList.add('hide');
  }

  #showSelectAcrossPagesOption(): void {
    const pagination = this.#getPagination();
    const optionText = `Select all ${pagination.totalCount} filtered submissions`;
    this.selectAcrossPagesTarget.textContent = optionText;

    this.selectAcrossPagesTarget.classList.remove('hide');
    this.deselectAcrossPagesTarget.classList.add('hide');
  }

  #showDeselectAcrossPagesOption(): void {
    this.selectAcrossPagesTarget.classList.add('hide');
    this.deselectAcrossPagesTarget.classList.remove('hide');
  }

  #togglePdfLinksEnabled(): void {
    const selectedCount = this.#getSelectedCount();
    const ignoreSelectionLimit = Feature.isActive('responses/bulk_download_pdf');
    const disableBulkPdf = !ignoreSelectionLimit && selectedCount > defaultPageSize;

    if (disableBulkPdf) {
      this.pdfDisabledMessageTarget.classList.remove('hide');
    } else {
      this.pdfDisabledMessageTarget.classList.add('hide');
    }

    this.pdfGenerationOptionTargets.forEach((element) => {
      this.#toggleElementEnabled(element, disableBulkPdf);
    });
  }

  #toggleElementEnabled(element: HTMLElement, disable: boolean): void {
    if (disable) {
      element.setAttribute('aria-disabled', '');
      element.setAttribute('disabled', '');
    } else {
      element.removeAttribute('aria-disabled');
      element.removeAttribute('disabled');
    }
  }

  #generateFormSetData(campaignId: number): Json {
    const queryParams = this.#getQueryParams();

    if (this.allSelectedAcrossPages) {
      return generateFilteredFormSetData(campaignId, queryParams);
    }

    const formIds = this.checkboxTargets
      .filter((checkbox) => { return checkbox.checked; })
      .map((checkbox) => { return checkbox.dataset.recordId; });
    return generateSelectedFormSetData(campaignId, formIds, queryParams);
  }

  #getSelectedCount(): number {
    if (this.allSelectedAcrossPages) {
      return this.#getPagination().totalCount;
    }

    return this.checkboxTargets
      .reduce((sum, checkbox) => { return sum + (checkbox.checked ? 1 : 0); }, 0);
  }

  #getQueryParams(): ResponsesQueryParams {
    return paramsFromUrl(new URL(window.location.href));
  }

  #getPagination(): Pagination {
    if (this.hasPaginationTarget) {
      return JSON.parse(this.paginationTarget.value) as Pagination;
    }

    return {
      page: 1,
      pageSize: defaultPageSize,
      totalCount: 0,
      totalPages: 0,
    };
  }
}

export default ResponsesSelectionController;
