import { fontRatio } from 'src/constants';

function fontSizeCss(val: number): { 'font-size': string; 'line-height': string } {
  const fontSize = Math.floor(val * fontRatio);
  const lineHeight = Math.floor((val + 1) * fontRatio);

  return { 'font-size': `${fontSize}px`, 'line-height': `${lineHeight}px` };
}

export default fontSizeCss;
