// @ts-strict-ignore
import { fontRatio } from 'src/constants';
import { findEl } from 'src/helpers/finders';

const MIN_WIDTH = 30;

function fieldWidth(
  fontSize?: number,
  content?: string | number | boolean | null,
): number {
  if (typeof content !== 'string') { return MIN_WIDTH; }

  const element: HTMLElement = findEl(document, 'span', '.field-ruler');
  element.textContent = content;
  element.style.fontSize = `${Math.floor(fontSize * fontRatio)}px`;
  const width = element.offsetWidth;

  return Math.max(width, MIN_WIDTH);
}

function fieldHeight(fontSize?: number): number {
  return Math.round(fontSize * fontRatio);
}

export { fieldWidth, fieldHeight };
