// @ts-strict-ignore
import Field, { Options } from 'src/doc_editor/field';
import { fieldHeight, fieldWidth } from 'src/doc_editor/fields/helpers/sizing';
import $template from 'src/helpers/dollar_template';
import iconClasses from 'src/helpers/icon';

class Attachment extends Field {
  constructor(opts: Options) {
    if (!opts.model.has('label')) {
      opts.model.set('label', 'Attachment');
    }
    super(opts);

    this.updateFieldSize();
    this.model.on('change:label', this.updateFieldSize);
  }

  $preview(): JQuery {
    const $previewElement = $template(
      'attachment-field-preview-template',
      { required: this.model.get('required') },
    );

    $previewElement.css({
      height: this.$element.height,
      left: this.$element.position().left,
      top: this.$element.position().top,
      width: this.$element.width,
    });

    const downloadIconClasses = iconClasses('arrowUpFromBracket');
    const label = this.model.get('label');
    const previewHtml = `<i class="${downloadIconClasses}"></i> ${label}`;
    $previewElement.find('.text-input').html(previewHtml);

    return $previewElement;
  }

  updateFieldSize(): void {
    const fontSize: number = this.model.get('fontSize');
    const label = `XX ${this.model.get('label')}`;

    this.model.set('width', fieldWidth(fontSize, label));
    this.model.set('height', fieldHeight(fontSize));
  }
}

export default Attachment;
