// @ts-strict-ignore
import without from 'lodash/without';

import { GetCurrentTool } from 'controllers/doc_editor_controller';
import
DocEditorSingleStore,
{ allFieldsLoaded, BoundGetGroupColumns, BoundUpdateDocEditor }
  from 'src/chux/doc_editor/single_store';
import { BoundGetFields, BoundRemoveFields, BoundUpdateFields }
  from 'src/chux/editable_fields/store';
import { SetActiveFields } from 'src/doc_editor/doc';
import FieldComponent, { Coordinates } from 'src/doc_editor/field';
import DocEditorFields from 'src/doc_editor/fields/index';
import FieldModel from 'src/doc_editor/fields/models/field';
import { assert } from 'src/helpers/assertion';
import BoundingBox from 'src/helpers/bounding_box';
import Feature from 'src/helpers/feature';
import { findEl } from 'src/helpers/finders';
import MouseSelectionTracker from 'src/helpers/mouse_selection_tracker';
import { events, publish, subscribe } from 'src/helpers/pub_sub';

import 'src/extensions/jquery-ui/droppable';

type Params = {
  getCurrentTool: GetCurrentTool;
  getFields: BoundGetFields;
  pageNum: number;
  removeFields: BoundRemoveFields;
  updateFields: BoundUpdateFields;
  updateDocEditor: BoundUpdateDocEditor;
  setActiveFields: SetActiveFields;
  dataSource: DataSource | null;
  getGroupColumns: BoundGetGroupColumns;
  steps: Step[] | null;
  store: typeof DocEditorSingleStore;
};

class DocPage {
  $element: JQuery;
  element: HTMLElement;
  $boundingBox: JQuery;
  params: Params;
  fields: FieldComponent[];

  constructor(element: HTMLElement, params: Params) {
    this.$element = $(element);
    this.element = element;
    this.params = params;
    this.fields = [];

    this.addToPage = this.addToPage.bind(this);
    this.bubbleMousedownInfo = this.bubbleMousedownInfo.bind(this);
    this.bubbleMouseupInfo = this.bubbleMouseupInfo.bind(this);
    this.updateBoundingBoxDisplay = this.updateBoundingBoxDisplay.bind(this);
    this.updateActiveFields = this.updateActiveFields.bind(this);
    this.removeFromPage = this.removeFromPage.bind(this);
    this.revertToPage = this.revertToPage.bind(this);
    this.repositionField = this.repositionField.bind(this);

    subscribe(events.FIELD_REMOVED, this.removeFromPage);
    subscribe(events.FIELD_DRAG_STARTED, this.removeFromPage);
    subscribe(events.FIELD_DRAG_REVERTED, this.revertToPage);
    subscribe(events.FIELD_MOVED, this.repositionField);
    this.$element
      .on('mousedown', this.bubbleMousedownInfo)
      .on('mouseup', this.bubbleMouseupInfo);

    if (Feature.isActive('doc_editor/hotkeys')) {
      this.initializeMultiSelect();
    }

    this.$element.droppable({
      accept: '.editor-field',
      drop: this.handleDrop.bind(this),
      tolerance: 'fit',
    });
  }

  get boundingBox(): HTMLElement {
    return findEl(this.element, 'div', '.bounding-box');
  }

  handleDrop(event: Event, ui: JQueryUI.DroppableEventUIParam): void {
    // this chunk of code is to make sure the stamp is positioned
    //   correctly relative to its new parent. The plugin default is
    //   to position the stamp relative to the old parent...
    const $target = $(event.target);
    const $editorField = ui.draggable;
    const field = $editorField.data('field');
    const offset = assert($editorField.offset());
    const targetOffset = assert($target.offset());

    $editorField[0].style.removeProperty('width');
    $editorField[0].style.removeProperty('height');

    field.setCoordinates({
      xPos: offset.left - targetOffset.left,
      yPos: offset.top - targetOffset.top,
    });
    this.$element.append($editorField);
    this.addToPage(field);
  }

  initializeMultiSelect(): void {
    this.$boundingBox = $(this.boundingBox);
    new MouseSelectionTracker(this.element, {
      onFinalSelection: this.updateActiveFields,
      onSelectionChange: this.updateBoundingBoxDisplay,
    });
  }

  updateBoundingBoxDisplay(selectedArea: BoundingBox): void {
    if (selectedArea && !this.params.getCurrentTool()) {
      this.$boundingBox.show().css({
        height: selectedArea.height,
        left: selectedArea.left,
        top: selectedArea.top,
        width: selectedArea.width,
      });
    } else {
      this.$boundingBox.hide();
    }
  }

  updateActiveFields(selectedArea: BoundingBox): void {
    this.$boundingBox.hide();

    if (this.params.getCurrentTool()) { return; }

    const storeFields = this.params.getFields();
    const selectedNumbers = this.getSelectedNumbers(selectedArea, storeFields);
    const set = new Set(selectedNumbers);

    this.params.setActiveFields(set);
  }

  getSelectedNumbers(
    selectedArea: BoundingBox,
    storeFields: FieldsByNumber,
  ): number[] {
    const fieldArea = new BoundingBox();

    const selectedFields = Object.values(storeFields).filter((field) => {
      const samePage = field.pageNum === this.params.pageNum + 1;

      fieldArea.setCoordinate1({ x: field.xPos, y: field.yPos });
      fieldArea.setCoordinate2({
        x: field.xPos + field.width,
        y: field.yPos + field.height,
      });

      return selectedArea.isOverlapping(fieldArea) && samePage;
    });

    return selectedFields.map((field) => { return field.number; });
  }

  bubbleMousedownInfo(event: JQuery.MouseDownEvent): void {
    const $target = $(event.target);

    if ($target.closest('.editor-field, .context-menu').length) { return; }

    const pageClickCoords = { xPos: event.pageX, yPos: event.pageY };

    publish(events.PAGE_MOUSEDOWN, { page: this, pageClickCoords });
  }

  bubbleMouseupInfo(): void { publish(events.PAGE_MOUSEUP); }

  removeFromPage(field: FieldComponent): FieldComponent {
    this.fields = without(this.fields, field);
    return field;
  }

  revertToPage(field: FieldComponent): void {
    if (this.getPageNum() === field.getPageNum()) {
      this.$element.append(field.$element);
      this.fields.push(field);
    }
  }

  addToPage(field: FieldComponent): void {
    this.fields.push(field);
    field.setPageData({
      pageHeight: this.$element.height(),
      pageNum: this.getPageNum(),
      pageWidth: this.$element.width(),
    });
  }

  loadFields(fieldModels: FieldModel[]): FieldComponent[] {
    const addedFields: FieldComponent[] = [];

    fieldModels.forEach((fieldModel) => {
      const view = new DocEditorFields[fieldModel.get('type')]({
        allFieldsLoaded,
        dataSource: this.params.dataSource,
        getFields: this.params.getFields,
        getGroupColumns: this.params.getGroupColumns,
        initCallbacks: true,
        model: fieldModel,
        removeFields: this.params.removeFields,
        setActiveFields: this.params.setActiveFields,
        steps: this.params.steps,
        store: this.params.store,
        updateDocEditor: this.params.updateDocEditor,
        updateFields: this.params.updateFields,
      });

      this.addToPage(view);
      addedFields.push(view);
    });

    const fieldElements = addedFields.map((field) => {
      return field.$element;
    });

    this.$element.append(fieldElements);

    return addedFields;
  }

  newField(model: FieldModel): FieldComponent {
    const [addedField] = this.loadFields([model]);
    this.repositionField(addedField);

    if (addedField.shouldShowContextMenuOnInit()) {
      addedField.showContextMenu();
    }
    return addedField;
  }

  clearFields(): void {
    this.fields.forEach((field) => {
      const payload = { number: field.getNumber() };

      this.params.removeFields(payload);
    });
    this.fields = [];
  }

  getFields(): FieldComponent[] {
    return this.fields;
  }

  hasValidPrefills(): boolean {
    return this.getFields().some((field) => {
      return field.getType() === 'Prefill' && field.isValid();
    });
  }

  getIndex(): number {
    return this.params.pageNum;
  }

  getPageNum(): number {
    return this.getIndex() + 1;
  }

  repositionField(field: FieldComponent): void {
    if (field.getPageNum() !== this.getPageNum()) { return; }

    const coords: Partial<Coordinates> = {};

    const fieldWidth = field.$element.outerWidth();
    const fieldHeight = field.$element.outerHeight();
    const pageWidth = assert(this.$element.width());
    const pageHeight = assert(this.$element.height());
    const { left: fieldLeft, top: fieldTop } = field.position();
    const fieldRight = fieldLeft + fieldWidth;
    const fieldBottom = fieldTop + fieldHeight;

    if (fieldLeft < 0) { coords.xPos = 0; }
    if (fieldRight > pageWidth && fieldWidth < pageWidth) {
      coords.xPos = pageWidth - fieldWidth;
    }

    if (fieldTop < 0) {
      coords.yPos = 0;
    } else if (fieldBottom > pageHeight) {
      coords.yPos = pageHeight - fieldHeight;
    }

    if (Object.entries(coords).length > 0) { field.setCoordinates(coords); }
  }
}

export default DocPage;
