// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Paginator from 'src/common/components/paginator';
import store from 'src/common/store';
import ValidatedFileInput, { Props as ValidatedFileInputProps }
  from 'src/components/validated_file_input';
import grab from 'src/helpers/grab';
import Notes, { Props as NotesProps } from 'src/responses/components/table/notes';
import ResponsesTableFrame from 'src/responses/components/table_frame';

const PATHS: { [key: string]: React.ComponentClass<any> | React.FC<any> } = {
  'src/common/components/paginator.tsx': Paginator,
  'src/components/validated_file_input': ValidatedFileInput,
  'src/responses/components/table/notes': Notes,
  'src/responses/components/table_frame': ResponsesTableFrame,
};

class ReactController extends Controller {
  static values = {
    componentPath: String,
    props: Object,
  };

  componentPathValue: string;
  propsValue: ValidatedFileInputProps & NotesProps;

  connect(): void {
    const component = this.component();

    ReactDOM.render(component, this.element);
  }

  component(): React.JSX.Element {
    const pathValue = this.componentPathValue.toString() as keyof typeof PATHS;
    const Component = grab(PATHS, pathValue);

    return (
      <Provider store={store}>
        <React.StrictMode>
          <Component {...this.propsValue} />
        </React.StrictMode>
      </Provider>
    );
  }

  disconnect(): void {
    ReactDOM.unmountComponentAtNode(this.element);
  }
}

export default ReactController;
