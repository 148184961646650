// @ts-strict-ignore
import { connect } from 'react-redux';

import ResponsesBulkActions from 'src/responses/components/bulk_actions';

function mapStateToProps(state: State) {
  const { responses } = state;

  return {
    statusGroup: responses.meta.queryParams.filters.statusGroup,
  };
}

export default connect(mapStateToProps)(ResponsesBulkActions);
