// @ts-strict-ignore
import Field from 'src/doc_editor/field';
import isFieldResizable from 'src/doc_editor/helpers/is_field_resizable';
import { assert } from 'src/helpers/assertion';
import $template from 'src/helpers/dollar_template';
import { findEl } from 'src/helpers/finders';
import sanitizeInput from 'src/helpers/sanitize_input';

class Input extends Field {
  config = {
    dragToleranceX: 0,
    dragToleranceY: 20,
    stampPreviewXOffset: -8,
    stampPreviewYOffset: -5,
  };

  $element: JQuery;

  constructor(opts) {
    super(opts);

    this.setResizable = this.setResizable.bind(this);
    this.onResizeStop = this.onResizeStop.bind(this);

    if (!opts.initCallbacks) { return; }

    this.setResizable();
    sanitizeInput(this.$element.find('.text-input'));
    this.model.on('change:format', this.setResizable);
  }

  get fieldContainer(): HTMLDivElement {
    return findEl(this.element, 'div', '.field-container');
  }

  $preview(): JQuery {
    const $previewElement = $template(
      'input-field-preview-template',
      { required: this.model.get('required') },
    );

    const $input = $previewElement.find('.text-input');

    $previewElement.css(this.$element.position());
    $input.css({
      height: this.$element.find('.requested-field').height(),
      width: this.$element.find('.requested-field').width(),
    }).html(this.$element.find('.text-input').html());

    return $previewElement;
  }

  previewOffset(): Coordinate {
    return {
      x: this.$element.outerWidth() + this.config.stampPreviewXOffset,
      y: this.$element.outerHeight() + this.config.stampPreviewYOffset,
    };
  }

  setResizable(): void {
    const resizable = isFieldResizable(this.model.toJSON());

    resizable ? this.enableResizing() : this.disableResizing();
  }

  enableResizing(): void {
    this.fieldContainer.dataset.resizeTarget = 'element';
    this.fieldContainer.dataset.resizeMinHeightValue = `${this.getMinHeight() - 2}`;
    this.fieldContainer.dataset.resizeMinWidthValue = `${this.getMinWidth() - 2}`;
    this.fieldContainer.dataset.controller = 'resize';

    this.fieldContainer.addEventListener('resize:stop', this.onResizeStop);
  }

  disableResizing(): void {
    Object.assign(this.fieldContainer.style, { height: '', width: '' });

    delete this.fieldContainer.dataset.resizeTarget;
    delete this.fieldContainer.dataset.resizeMinHeightValue;
    delete this.fieldContainer.dataset.resizeMinWidthValue;
    delete this.fieldContainer.dataset.controller;

    this.element.removeEventListener('resize:stop', this.onResizeStop);
  }

  onResizeStop(): void {
    const height = assert(this.fieldContainer.offsetHeight);
    const width = assert(this.fieldContainer.offsetWidth);
    this.setFieldSize({ height: height - 2, width: width - 2 });
  }
}

export default Input;
