// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash/debounce';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from 'src/common/store';
import { pageReloadInterval } from 'src/constants';
import { findEl } from 'src/helpers/finders';
import Location from 'src/helpers/location';
import template from 'src/helpers/template';
import { updateMeta } from 'src/responses/action_creators';
import ResponsesIndexContainer from 'src/responses/containers/index';
import { paramsFromUrl, urlFromParams } from 'src/responses/helpers/query_params';

type Props = {
  campaign: Campaign;
  steps: Step[];
  searchParams: ResponsesQueryParams;
  headers: Responses.TableHeader[];
};

class ResponsesIndexController extends Controller {
  static targets = [
    'pagination',
    'responsesFrame',
    'responsesLoader',
    'responsesTable',
    'searchPaginator',
  ];

  static values = {
    props: Object,
  };

  paginationTarget: HTMLInputElement;
  propsValue: Props;
  responsesFrameTarget: HTMLElement;
  responsesTableTarget: HTMLElement;
  responsesLoaderTarget: HTMLElement;
  searchPaginatorTarget: HTMLElement;

  connect(): void {
    Location.autoReload(pageReloadInterval);

    const { campaign, headers, searchParams } = this.propsValue;
    this.#updateQueryParams(searchParams);

    this.handleSearch = debounce(this.handleSearch, 500).bind(this);

    ReactDOM.render(this.#wrapper(campaign, headers), this.element);

    const baseUrl = `${window.location.origin}${window.location.pathname}`;
    const url = urlFromParams(searchParams, baseUrl).toString();
    this.#navigateResponsesFrame(url.toString());
    this.#updateSidebar(searchParams);
  }

  loadResponses(event: { detail: { url: string } }): void {
    this.#navigateResponsesFrame(event.detail.url);
  }

  showLoader(event: { detail: { url: string } }): void {
    const newFrameUrl = new URL(event.detail.url);
    this.#updateUrl(newFrameUrl);
    this.responsesLoaderTarget.classList.remove('hide');
    this.responsesTableTarget.classList.add('hide');
  }

  hideLoader(): void {
    const pagination = this.#getPagination(this);
    this.#regenerateSearchPaginator(pagination);
    this.responsesLoaderTarget.classList.add('hide');
    this.responsesTableTarget.classList.remove('hide');
  }

  applyPage(event: ActionEvent<{ pageNum: number }>): void {
    const { pageNum } = event.params;
    const { page: currentPageNum } = this.#getPagination(this);

    if (pageNum === currentPageNum) { return; }

    const url = new URL(window.location.href);
    url.searchParams.set('page', pageNum.toString());
    this.#navigateResponsesFrame(url.toString());
  }

  handleSearch(event: HTMLInputEvent): void {
    const searchTerm = event.target.value.trim();
    const { searchTerm: currentSearchTerm } = this.#getQueryParams();

    if ((currentSearchTerm || '').trim() === searchTerm) { return; }

    if (searchTerm) {
      this.#applySearchTerm(searchTerm);
    } else {
      this.#removeSearchTerm();
    }
  }

  clearSearch(): void {
    this.#removeSearchTerm();
  }

  handleSort(event: ActionEvent<{ sortBy: string }>): void {
    const { sortBy } = event.params;

    if (!sortBy) { return; }

    const { direction: currentDirection, sortBy: currentSortBy } =
      this.#getQueryParams();

    let direction: SortDirection;

    if (currentSortBy === sortBy && currentDirection === 'asc') {
      direction = 'desc';
    } else {
      direction = 'asc';
    }

    this.#applySort(sortBy, direction);
  }

  #applySort(sortBy: string, direction: string): void {
    const url = new URL(window.location.href);

    url.searchParams.set('direction', direction);
    url.searchParams.set('sort_by', sortBy);
    url.searchParams.set('page', '1');
    this.#navigateResponsesFrame(url.toString());
  }

  #applySearchTerm(searchTerm: string): void {
    const url = new URL(window.location.href);

    url.searchParams.set('search_term', searchTerm);
    url.searchParams.set('page', '1');
    this.#navigateResponsesFrame(url.toString());
  }

  #removeSearchTerm(): void {
    const url = new URL(window.location.href);

    url.searchParams.delete('search_term');
    url.searchParams.set('page', '1');
    this.#navigateResponsesFrame(url.toString());
  }

  #navigateResponsesFrame(url: string): void {
    this.responsesFrameTarget.setAttribute('src', url);
  }

  #updateUrl(url: URL): void {
    const queryParams = paramsFromUrl(url);
    this.#updateQueryParams(queryParams);
    this.#updateSidebar(queryParams);
  }

  #getQueryParams(): ResponsesQueryParams {
    return paramsFromUrl(new URL(window.location.href));
  }

  #updateQueryParams(queryParams: ResponsesQueryParams): void {
    const baseUrl = `${window.location.origin}${window.location.pathname}`;
    const url = urlFromParams(queryParams, baseUrl);
    Location.replaceHistoryState(url.search);
    store.dispatch(updateMeta({ queryParams }));
  }

  #updateSidebar(queryParams: ResponsesQueryParams): void {
    const { searchTerm } = queryParams;
    const { statusGroup } = queryParams.filters;
    this.dispatch('update-sidebar', { detail: { searchTerm, statusGroup } });
  }

  #regenerateSearchPaginator(pagination: Pagination): void {
    const props = JSON.stringify({
      ariaLabel: 'Responses Search Pagination',
      compactDisplay: true,
      pagination,
      paginatorClassNames: 'responses-pagination-top',
    });

    const paginatorTemplate = template(
      'responses-search-paginator-template',
      { props },
    );

    const containerSelector = '#search-paginator';
    const container = findEl(document, 'div', containerSelector);
    container.innerHTML = paginatorTemplate;
  }

  #getPagination(controller: ResponsesIndexController): Pagination {
    return JSON.parse(controller.paginationTarget.value) as Pagination;
  }

  #wrapper(
    campaign: Campaign,
    headers: Responses.TableHeader[],
  ): React.JSX.Element {
    return (
      <Provider store={store}>
        <React.StrictMode>
          <ResponsesIndexContainer
            campaign={campaign}
            headers={headers}
          />
        </React.StrictMode>
      </Provider>
    );
  }
}

export type { Props };
export default ResponsesIndexController;
