// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import BoundingBox from 'src/helpers/bounding_box';
import MouseSelectionTracker from 'src/helpers/mouse_selection_tracker';

type ModeChangedEvent = { detail: { mode: string } };

class FieldRulesController extends Controller<HTMLElement> {
  static targets = ['boundingBox', 'field'];

  boundingBoxTarget!: HTMLElement;
  fieldTargets!: HTMLElement[];

  modeValue: string;

  get affectedMode(): boolean {
    return this.modeValue === 'affectedMode';
  }

  connect(): void {
    this.updateBoundingBoxDisplay = this.updateBoundingBoxDisplay.bind(this);
    this.updateActiveFields = this.updateActiveFields.bind(this);

    new MouseSelectionTracker(this.element, {
      onFinalSelection: this.updateActiveFields,
      onSelectionChange: this.updateBoundingBoxDisplay,
    });
  }

  setMode({ detail: { mode } }: ModeChangedEvent): void {
    this.modeValue = mode;
  }

  updateActiveFields(selectedArea: BoundingBox): void {
    if (!this.affectedMode) {
      return;
    }

    this.hideBoundingBox();

    this.fieldTargets.forEach((field) => {
      if (selectedArea.isOverlapping(this.fieldArea(field))) {
        this.dispatch(
          'fieldSelected',
          { detail: {
            fieldLabel: field.dataset.fieldLabel,
            fieldNumber: field.dataset.fieldNumber,
          } },
        );
      }
    });
  }

  updateBoundingBoxDisplay(selectedArea: BoundingBox): void {
    if (selectedArea && this.affectedMode) {
      this.boundingBoxTarget.style.display = 'block';
      this.boundingBoxTarget.style.width = `${selectedArea.width}px`;
      this.boundingBoxTarget.style.height = `${selectedArea.height}px`;
      this.boundingBoxTarget.style.left = `${selectedArea.left}px`;
      this.boundingBoxTarget.style.top = `${selectedArea.top}px`;
    } else {
      this.hideBoundingBox();
    }
  }

  private hideBoundingBox(): void {
    this.boundingBoxTarget.style.display = 'none';
  }

  private fieldArea(field: HTMLElement): BoundingBox {
    const pageRect = this.element.getBoundingClientRect();
    const fieldRect = field.getBoundingClientRect();
    const xPos = fieldRect.x - pageRect.x;
    const yPos = fieldRect.y - pageRect.y;

    const fieldArea = new BoundingBox();
    fieldArea.setCoordinate1({ x: xPos, y: yPos });
    fieldArea.setCoordinate2({
      x: xPos + fieldRect.width,
      y: yPos + fieldRect.height,
    });

    return fieldArea;
  }
}

export default FieldRulesController;
