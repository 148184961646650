import EditableFieldsCollection from 'src/chux/editable_fields/collection';
import ReduxTransitionalStore from 'src/chux/redux_transitional_store';

const EditableFieldsStore = new ReduxTransitionalStore(EditableFieldsCollection);

type BulkSetPayload = { required: boolean } | { stepId: number };

function bulkSetFields(payload: BulkSetPayload): void {
  EditableFieldsStore.dispatch({ payload, type: 'fields/BULK_SET' });
}

function getFields(): FieldsByNumber {
  return EditableFieldsCollection.getState();
}

function removeFields({ number }: { number: number }): void {
  EditableFieldsStore.remove(number);
}

function updateFields(payload: Partial<Field> | Partial<Field>[]): void {
  EditableFieldsStore.dispatch({
    payload: Array.isArray(payload) ? payload : [payload],
    type: 'fields/UPDATE',
  });
}

type BoundGetFields = typeof getFields;
type BoundUpdateFields = typeof updateFields;
type BoundRemoveFields = typeof removeFields;

export default EditableFieldsStore;
export { bulkSetFields, getFields, removeFields, updateFields };
export type { BoundGetFields, BoundUpdateFields, BoundRemoveFields };
