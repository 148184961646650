// @ts-strict-ignore
import Backbone from 'backbone';

import DocEditorSingleStore from 'src/chux/doc_editor/single_store';
import FieldComponent from 'src/doc_editor/field';
import DocEditorFields from 'src/doc_editor/fields/index';
import { assert } from 'src/helpers/assertion';

type Params = {
  steps: Step[] | null;
};

class StampPreview {
  $element: JQuery;
  element: HTMLElement;
  params: Params;
  field: FieldComponent | null;
  tool: FieldType | null = null;

  constructor(element: HTMLElement, params: Params) {
    this.$element = $(element);
    this.element = element;
    this.params = params;
  }

  show(): void {
    if (this.tool) { this.$element.css('visibility', 'visible'); }
  }

  hide(): void { this.$element.css('visibility', 'hidden'); }

  setStamp(newTool: FieldType, fontSize: number): void {
    this.tool = newTool;

    if (this.tool) {
      this.field = new DocEditorFields[this.tool]({
        initCallbacks: false,
        model: new Backbone.Model({ type: this.tool }),
        steps: this.params.steps,
        store: DocEditorSingleStore,
      });
      this.$element.html(this.field.element);
      this.field.setFontSize(fontSize);
    } else {
      this.field = null;
      this.hide();
    }
  }

  setFontSize(fontSize: number): void {
    if (this.field) {
      this.field.setFontSize(fontSize);
    }
  }

  setPosition(xPos: number, yPos: number): void {
    if (this.field) {
      const previewOffset = this.field.previewOffset();

      this.$element.css('left', xPos - previewOffset.x);
      this.$element.css('top', yPos - previewOffset.y);
    }
  }

  outerWidth(): number {
    if (this.field) {
      return this.field.$element.find('.field-container').outerWidth();
    }
    return 0;
  }

  outerHeight(): number {
    if (this.field) {
      return this.field.$element.find('.field-container').outerHeight();
    }
    return 0;
  }

  width(): number {
    if (this.field) {
      return this.field.$element.find('.field-container').width();
    }
    return 0;
  }

  height(): number {
    if (this.field) {
      return this.field.$element.find('.field-container').height();
    }
    return 0;
  }

  xPos(): number {
    return this.field ? this.offset().left : 0;
  }

  yPos(): number {
    return this.field ? this.offset().top : 0;
  }

  offset(): JQuery.Coordinates {
    return assert(this.$element.offset());
  }
}

export default StampPreview;
